<template>
  <div class="box">
    <div class="top">
      <!-- <img :src="info.qrCodeFullPath" alt="" class="user-photo" /> -->
      <van-image
        width="100"
        height="100"
        :src="info.profilePhotoFullPath"
        fit="cover"
        round
        class="user-photo"
      />
      <div class="top-right">
        <div>你的专属顾问</div>
        <div class="name">{{ info.name }}</div>
        <div>{{ info.phone }}</div>
        <div>已为{{ info.solveNum }}名客户解决问题</div>
      </div>
    </div>
    <div class="bottom">
      <img :src="bg" alt="" class="bg" />
      <img :src="info.qrCodeFullPath" class="ma" />
      <div class="bottom-name">
        <div>{{ info.name }}</div>
        <div>{{ info.companyName }}</div>
      </div>
    </div>
    <div class="button" @click="callPhone" v-if="info.phone">
      拨打免费专属客服电话
    </div>
  </div>
</template>

<script>
import url from "@/service/url-config";
export default {
  data() {
    return {
      bg: require("@/img/consultant-bg.png"),
      info: {
        profilePhotoFullPath: require("@/img/my/user-photo.png"),
      },
    };
  },
  created() {
    this.$http.get(url.consultant).then((res) => {
      this.info = res.data;
      if (!this.info.profilePhotoFullPath) {
        this.info.profilePhotoFullPath = require("@/img/my/user-photo.png");
      }
    });
  },
  methods: {
    // 调用拨号功能
    callPhone() {
      window.location.href = "tel://" + this.info.phone;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #f4f5f9;
  min-height: calc(100vh - 56px);
  padding-top: 56px;
  .top {
    width: 345px;
    height: 143px;
    box-shadow: 2px 2px 4px 1px rgba(241, 241, 241, 1);
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .user-photo {
      width: 98px;
      height: 98px;
      border-radius: 50%;
      margin-right: 30px;
      margin-left: 25px;
    }
    .top-right {
      height: 100px;
      font-size: 12px;
      color: #333333;
      line-height: 26px;
      .name {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .bottom {
    width: 345px;
    height: 340px;
    background: #036fe5;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    position: relative;
    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .ma {
      width: 145px;
      height: 145px;
      position: absolute;
      left: 50%;
      margin-left: -72.5px;
      top: 50%;
      margin-top: -72.5px;
    }
    .bottom-name {
      position: absolute;
      bottom: 20px;
      right: 60px;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .button {
    width: 220px;
    height: 42px;
    background: #1377ff;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 50px;
  }
}
</style>
